.shadow{
  position: fixed;
  top: -100px;
  right: -100px;
  bottom: -100px;
  left: -100px;
  background-color: $color-black ;
  z-index: -10;
  transition: all .250s ease-in;
  opacity: 0;
}

.shadow--transition-none {
  transition: all 0s ease-in;
}

.shadow--active {
  z-index: 999;
  opacity: 1;
}