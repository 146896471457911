.header {
  padding-top: 13px;
  margin-bottom: 25px;

  // @media(max-width: 540px) {
  //   padding-top: 0px;
  // }

  .container {
    position: relative;
  }

  .logo--desktop {
    & svg {
      width: 100%;
      height: 100%;
    }

    @media(max-width: 1024px) {
      display: none;
    }
  }

  .logo--mobile {
    display: none;
    
    @media(max-width: 1024px) {
      display: inline-block;
      width: 100%;
    }
  }

  .logo--big {
    width: 100%;
    margin: auto;

    & img {
      width: 100%;
    }

    & svg {
      width: 100%;
    }
  }

  .logo--small {
    // position: absolute;
    // top: 15px;
    z-index: 99;

    @media(max-width:540px) {
      position: absolute;
      top: 15px;
      left: 50%;
      transform: translateX(-50%);
    }

    & img {
      width: 90px;

      @media(max-width: 1024px) {
        width: 110px; 
      }
    }
  }

  &__wrapper {
    display: flex;
    align-items: center;

    @media(max-width: 768px) {
      padding-top: 10px;
    }

    @media(max-width: 540px) {
      flex-direction: column;
      padding-top:80px;
    }
  }

  &__nav {
    @media(min-width: 1024px){
      transform: translateY(-40px);
    }

    &-list {
      display: flex;
      align-items: center;

      @media(max-width: 1024px) {
        justify-content: center;
      }

      @media(max-width: 540px) {
        justify-content: space-between;
      }
    }

    &-item {
      text-transform: uppercase;
      font-size: 20px;
      
      @media(max-width: 540px) {
        font-size: 18px;
      }

      &:not(:last-child) {
        margin-right: 50px;

        @media(max-width: 540px) {
          margin-right: 0;
        }
      }

      & a {
        transition: $transition;
        position: relative;
        padding-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 2px;
          background-color: $color-white;
          transition: .2s all ease;
        }

        &:hover::before {
          width: 100%;
        }
      }

      & a:hover {
        opacity: .7;
      }
    }
  } 

  &__nav-second {
    margin-left: auto;

    @media(max-width: 540px) {
      margin: auto;
      padding-top: 20px;
      padding-bottom: 15px;
      width: 100%;
    }

    &-list {
      display: flex;

      @media(max-width: 540px) {
        width: 100%;
        justify-content: space-between;
      }
    }

    &-item {
      text-transform: uppercase;
      font-size: 20px;

      @media(max-width:540px) {
        font-size: 18px;
      }

      &--art{
        @media(max-width:540px) {
          order: 2;
        }
      }

      &--about{
        @media(max-width:540px) {
          order: 1;
        }
      }

      &--contacts{
        @media(max-width:540px) {
          order: 3;
        }
      }

      &:not(:first-child) {
        margin-left: 130px;

        @media(max-width: 1024px) {
          margin-left: 110px;
        }

        @media(max-width: 768px) {
          margin-left: 70px;
        }

        @media(max-width: 540px) {
          margin-left: 0px;
        }
      }
    }

    &-link {
      transition: $transition;
      position: relative;
      padding-bottom: 5px;
      &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 0%;
        height: 2px;
        background-color: $color-white;
        transition: .2s all ease;
      }

      &:hover::before {
        width: 100%;
      }

      &:hover {
        opacity: .7;
      }
    }
  }

  &__nav-second--picture {
    .header__nav-second-item {
      &:not(:first-child) {
        margin-left: 163px;

        @media(max-width: 768px) {
          margin-left: 85px;
        }

        @media(max-width: 540px) {
          margin-left: 0px;
        }
      }
    }
  }

  &__breadcrumbs {
    position: relative;
    max-width: 600px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 15px;

    @media(max-width:1024px) {
      justify-content: flex-end;
      max-width: 1024px;
    }

    @media(max-width:540px) {
      display: none;
    }

    &-item {
      text-transform: uppercase;
      font-size: 20px;
      margin-left: 18%;

      &--mobile {
        @media(max-width:1024px) {
          display: none;
        }
      }

      &--picture {
        margin-left: 19.7%;
      }
    }

    &-current {
      & a {
        transition: $transition;
        position: relative;
        padding-bottom: 5px;
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 0%;
          height: 2px;
          background-color: $color-white;
          transition: .2s all ease;
        }

        &:hover::before {
          width: 100%;
        }
      }
    }

    &-prev {
      position: relative;
      cursor: pointer;  
      transition: $transition;

      @media(max-width: 1024px) {
        text-align: right;
      }
      
      &:hover {
        opacity: .7;
      }

      & a {
        position: relative;
        padding-left: 75px;

        & .row-justify-fe a {
          text-align: right;
        }

        & .row-justify-fs a {
          text-align: left;
        }

        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          width: 57px;
          height: 18px;
          background-image: url('../assets/images/svg/arrow--left.svg');
        }
      }
    }
  }
}

.header-main {
  margin: 0;
  padding-left: 24px;
  padding-right: 24px;

  @media(max-width: 540px) {
    padding-left: 12px;
    padding-right: 12px;
  }

  .container {
    @media(max-width: 1024px) {
      height: 100vh;
    }

    @media(max-width: 1024px) {
      height: 98vh;
    }
  }

  .header__nav {
    @media(max-width: 1024px) {
      width: 90%;
      position: absolute;
      bottom: 30px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}