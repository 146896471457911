.full-catalog{
  font-size: 18px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 22px;
  border: 1px solid $color-white;
  color: $color-white;
  text-transform: uppercase;
  text-decoration: underline;
  line-height: 1em;
  transition: $transition;
  margin-bottom: 24px;
  margin-top: 24px;

  &:hover {
    opacity: .7;
    text-decoration: underline;
  }

  @media(max-width: 540px) {
    margin-top: 30px;
  }

  @media(min-width: 541px) {
    display: none;
  }
}