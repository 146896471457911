.image {
  // padding-left: 24px;
  // padding-right: 24px;
  transition: all .3s ease;

  &-desktop {
    @media(max-width: 1024px) {
      display: none;
    }
  }

  &-mobile {
    @media(min-width: 541px){
      display: none;
    }
  }

  &-tablet {
    display: none;
    @media(max-width: 1024px) {
      display: block;
    }

    @media(max-width: 540px) {
      display: none;
    }
  }

  &--width-auto {
    width: auto!important;
  }

  &-end {
    opacity: 0;
    animation: .3s ease-in both end;
    animation-delay: .3s;
  }

  @keyframes end {
    from {
      opacity: 0;
    }

    to{
      opacity: 1;
    }
  }

  &__item {
    flex-grow: 1;
    position: relative;

    @media (max-width: 540px) {
      margin-bottom: 20px;
    }

    &-text {
      &--tablet{
        @media(min-width: 1025px) {
          display: none;
        }
      }

      &--desktop{
        @media(max-width: 1024px) {
          display: none;
        }
      }
    }

    &--padding {
      padding-left: 24px;
      padding-right: 24px;
    }
    
    &--center {
      display: flex;
      align-items: center;

      & img {
        margin: auto;
      }
    }

    & img {
      width: 100%;
    }
  }

  &__container {
    display: flex;
    position: relative;
    margin-bottom: 30px;

    @media (max-width: 540px) {
      flex-direction: column-reverse;
    }

    &--center {
      justify-content: center;
    }

    &--space-between {
      justify-content: space-between;
    }
  }

  &__animation {
    position: relative;
    flex-grow: 1;
    padding-right: 24px;

    @media (max-width: 540px) {
      padding-right: 0;
    }

    &-text {
      font-size: 18px;
      line-height: 1.27em;
      max-width: 370px;
      position: absolute;
      text-align: center;
      left: 50%;
      top: 55%;
      transform: translateX(-50%);

      @media(max-width: 1024px) {
        font-size: 14px;
      }

      @media(max-width: 540px) {
        left: inherit;
        transform: inherit;
      }
    }

    &-1 {
    }

    &-2 {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: .3s ease-in;
    }

    // &:hover &-2 {
    //   opacity: 1;
    // }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    margin-bottom: 30px;
    text-transform: uppercase;
    
    @media(max-width: 540px) {
      font-size: 18px;
    }
  }

  &__finish {
    text-align: center;
    font-weight: 500;
    font-size: 76px;
    line-height: 100%;
    text-transform: uppercase;
    margin-bottom: 37px;

    @media(max-width: 540px) {
      font-size: 55px;
    }
  }

  &__link {
    text-align: center;
    font-size: 18px;
    text-transform: uppercase;
    transition: $transition;
    
    &:hover {
      opacity: .7;
    }
    
    & a {
      color: $color-white;
      border-bottom: 1px solid $color-white;
    }

    & span {
      cursor: pointer;
      color: $color-white;
      border-bottom: 1px solid $color-white;
    }
  }

  &__text {
    font-weight: 400;
    max-width: 670px;
    margin: auto;
    font-size: 18px;
    text-align: center;
    line-height: 1.27em;
    margin-bottom: 30px;

    @media(max-width: 540px) {
      font-size: 16px;
    }
  }

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 142px;

    @media (max-width: 540px) {
      margin-bottom: 0px;
    }
  }

  &--full-width {
    max-width: 1600px;
    margin: auto;
    width: 100%;
    margin-bottom: 78px;

    & img {
      width: 100%;
    }
  }

  &-mb-140 {
    margin-bottom: 140px;

    @media (max-width: 540px) {
      margin-bottom: 70px;
    }
  }

  &-mb-200 {
    margin-bottom: 200px;

    @media (max-width: 540px) {
      margin-bottom: 120px;
    }
  }

  &-mb-400 {
    margin-bottom: 400px;

    @media (max-width: 540px) {
      margin-bottom: 200px;
    }
  }
}