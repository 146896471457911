.preloader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: $color-black;
  z-index: 999;

  &-container {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -70px!important;
  }

  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    
    & img {
      @media(max-width: 540px) {
        width: 100%;
      }
    }
  }

  &__image{
    position: absolute;

    &--1{
      left: 50%;
      top: 15%;
      transform: translateX(-50%);

      @media (max-width: 540px) {
        top: 25%;
      }
    }

    &--2{
      right: 15%;
      top: 75%;
    }

    &--3{
      left: 15%;
      top: 55%;

      @media (max-width: 540px) {
        top: 65%;
      }
    }
  }

  &__progressbar {
    position: absolute;
    bottom: 5%;
    left: 24px;
    right: 24px;
    height: 1px;
    background-color: $color-white;

    &-loader{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 0%;
      height: 7px;
      background-color: $color-white;
      animation: 1.5s ease-in both loader;
      transition: .3s ease-in;
    }
  }
}

.loader--fill {
  animation: .5s ease-in both loaderFill;
  width: 100%;
}

.hide-animation {
  opacity: 0;
  transition: all .15s ease-in;
}

.hide-animation--long {
  opacity: 0;
  transition: all .6s ease-in;
  z-index: -9999;
}

@keyframes loader {
  from {
    width: 0%;
  }

  to {
    width: 70%  ;
  }
}

@keyframes loaderFill {
  from {
    width: 70%;
  }

  to {
    width: 100%  ;
  }
}


