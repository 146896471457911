.art-cards {
  @media(max-width: 1024px) {
    padding-top: 10px;
  }

  @media(max-width: 540px) {
    display: none;
  }

  &__item {
    width: 100%;
    height: 100%;
    border: 1px solid $color-white;
    position: relative;
    transition: all .2s ease;

    & a {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 10;
    }

    & img {
      width: 100%;
    }

    &:hover {
      opacity: .7;
    }
    
  }

  &__text {
    text-transform: uppercase;
    border-bottom: 1px solid $color-white;
    font-size: 20px;
    transition: all .2s ease;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .col {
    margin-bottom: 30px;
  }
}