.to-top {
  position: fixed;
  bottom: 50px;
  right: 24px;
  width: 79px;
  height: 79px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $color-white;
  border-radius: 100%;
  font-size: 18px;
  z-index: 99;
  background: rgba(0, 0, 0, 0.52);
  border: 1px solid #FFFFFF;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(13px);
  opacity: 0;
  z-index: -99;
  transition: all .3s ease-in;
  cursor: pointer;

  &--active {
    opacity: 1;
    z-index: 10;
  }

  & span {
    transition: $transition;
    position: relative;
    padding-bottom: 5px;
    &::before {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0%;
      height: 2px;
      background-color: $color-white;
      transition: .2s all ease;
    }
  }

  &:hover span::before {
    width: 100%;
  }
}