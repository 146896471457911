.video {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  background-color: black;
  z-index: -1;
 
  
  & video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    @media(max-width: 1024px) {
      width: 2048px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
    }

    @media(max-width: 540px) {
      width: 1150px;
      transform: translate(-50%,-50%);
    }
  }

  &-main { 
    z-index: -10;
  }

  &-secondary{
    z-index: -1;
  }

  &-art, &-about, &-contacts {
    z-index: -20;
  }
}
