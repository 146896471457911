.collection-title {
  margin-bottom: 200px;
  padding-top: 260px;

  @media(max-width: 540px) {
    padding-top: 50px;
    margin-bottom: 0px
  }

  &__image {
    text-align: center;
    margin-bottom: 40px;
    max-width: 647px;
    margin: auto;
    margin-bottom: 30px;

    @media(max-width: 540px) {
      width: 100%;
      margin-bottom: 15px;
    }

    & img {
      width: 100%;
      @media(max-width: 540px) {
        width: 100%;
      }
    }
  }

  &__text {
    text-align: center;
    max-width: 680px;
    margin: auto;
    font-size: 18px;

    @media(max-width: 540px) {
      font-size: 16px;
    }
  }
}