

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.ball-chasing .loader {
  width: 100px;
  height: 100px;
  animation: rotate 2s linear infinite; }
  .ball-chasing .loader .ball-1, .ball-chasing .loader .ball-2 {
    position: absolute;
    top: 0;
    width: 60%;
    height: 60%;
    border-radius: 50%;
    background-color: #fff;
    animation: chasingBallBounce 2s linear infinite; }
  .ball-chasing .loader .ball-2 {
    top: auto;
    bottom: 0;
    animation-delay: -1s; }

@keyframes chasingBallBounce {
  50% {
    transform: scale(0); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.ball-pulse .loader {
  width: 100px;
  height: 100px; }
.ball-pulse .ball {
  margin: 25% 0 0 25%;
  width: 50%;
  height: 50%;
  background-color: #FFF;
  border-radius: 50%;
  animation: ballPulse 2s ease-in-out infinite; }

@keyframes ballPulse {
  0% {
    transform: scale(0); }
  100% {
    transform: scale(1);
    opacity: 0; } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.ball-pulse-double .loader {
  width: 100px;
  height: 100px; }
.ball-pulse-double .ball-1, .ball-pulse-double .ball-2 {
  position: absolute;
  top: 25%;
  left: 25%;
  width: 50%;
  height: 50%;
  background-color: #FFF;
  border-radius: 50%;
  animation: ballPulseDouble 2s ease-in-out infinite;
  opacity: .5; }
.ball-pulse-double .ball-2 {
  animation-delay: -1s; }

@keyframes ballPulseDouble {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.wave .loader {
  width: 100px;
  height: 100px; }
  .wave .loader > div {
    float: left;
    margin: 40% 2% 0 0;
    width: 4%;
    height: 16%;
    background-color: #FFF;
    animation: wave 1s cubic-bezier(0.2, 0.68, 0.18, 1.08) infinite; }
  .wave .loader .line-1 {
    margin-left: 30%; }
  .wave .loader .line-1 {
    animation-delay: 0.1s; }
  .wave .loader .line-2 {
    animation-delay: 0.2s; }
  .wave .loader .line-3 {
    animation-delay: 0.3s; }
  .wave .loader .line-4 {
    animation-delay: 0.4s; }
  .wave .loader .line-5 {
    animation-delay: 0.5s; }

@keyframes wave {
  0%,100% {
    transform: scaleY(1); }
  50% {
    transform: scaleY(2); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.wave-spread .loader {
  width: 100px;
  height: 100px; }
  .wave-spread .loader > div {
    float: left;
    margin: 40% 2% 0 0;
    width: 4%;
    height: 16%;
    background-color: #FFF;
    animation: wave 1s cubic-bezier(0.85, 0.25, 0.37, 0.85) infinite; }
  .wave-spread .loader .line-1 {
    margin-left: 30%; }
  .wave-spread .loader .line-1, .wave-spread .loader .line-5 {
    animation-delay: .4s; }
  .wave-spread .loader .line-2, .wave-spread .loader .line-4 {
    animation-delay: .2s; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.circle-pulse .loader {
  width: 100px;
  height: 100px; }
  .circle-pulse .loader > div {
    margin: 23px 0 0 23px;
    width: 50%;
    height: 50%;
    border: 2px solid #FFF;
    border-radius: 50%;
    animation: circlePulse 1s ease infinite; }

@keyframes circlePulse {
  0% {
    transform: scale(0.1); }
  80% {
    opacity: .8; }
  100% {
    transform: scale(1);
    opacity: 0; } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.circle-pulse-multiple .loader {
  width: 100px;
  height: 100px; }
  .circle-pulse-multiple .loader > div {
    position: absolute;
    top: 24px;
    left: 24px;
    width: 50%;
    height: 50%;
    border: 2px solid #FFF;
    border-radius: 50%;
    animation: circlePulse 1s ease infinite; }
  .circle-pulse-multiple .loader .circle-2 {
    animation-delay: -.9s; }
  .circle-pulse-multiple .loader .circle-3 {
    animation-delay: -.8s; }

@keyframes circlePulse {
  0% {
    transform: scale(0.1); }
  80% {
    opacity: .8; }
  100% {
    transform: scale(1);
    opacity: 0; } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.arc-rotate-double .loader {
  width: 100px;
  height: 100px; }
  .arc-rotate-double .loader > div {
    position: absolute;
    border: 2px solid;
    border-color: transparent #FFF;
    border-radius: 50%;
    animation: load 1s infinite ease-in-out; }
  .arc-rotate-double .loader .arc-1 {
    margin: 32% 0 0 32%;
    width: 36%;
    height: 36%; }
  .arc-rotate-double .loader .arc-2 {
    margin: 40% 0 0 40%;
    width: 20%;
    height: 20%;
    animation-direction: reverse;
    animation-delay: -0.5s; }

@keyframes load {
  0% {
    transform: rotate(0) scale(1); }
  50% {
    transform: rotate(180deg) scale(0.6); }
  100% {
    transform: rotate(360deg) scale(1); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.arc-rotate .loader {
  width: 100px;
  height: 100px; }
  .arc-rotate .loader .arc {
    position: absolute;
    border: 2px solid;
    border-color: transparent #FFF #FFF #FFF;
    border-radius: 50%;
    margin: 32% 0 0 32%;
    width: 36%;
    height: 36%;
    animation: single-arc-rotate 1s infinite linear; }

@keyframes single-arc-rotate {
  100% {
    transform: rotate(360deg); } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.square-split .loader {
  width: 100px;
  height: 100px; }
  .square-split .loader > div {
    float: left;
    width: 50%;
    height: 50%; }
    .square-split .loader > div::after {
      content: '';
      display: block;
      margin: 50% 0 0 50%;
      width: 50%;
      height: 50%;
      background: #FFF;
      animation: squareSplit 2s infinite ease-in-out alternate; }
  .square-split .loader .square-2 {
    transform: rotate(90deg); }
  .square-split .loader .square-3 {
    transform: rotate(270deg);
    animation-direction: reverse; }
  .square-split .loader .square-4 {
    transform: rotate(180deg);
    animation-direction: reverse; }

@keyframes squareSplit {
  20% {
    transform: rotate(0deg);
    margin: 50% 0 0 50%; }
  50% {
    transform: rotate(90deg);
    margin: 0px; }
  80% {
    transform: rotate(180deg);
    margin: 50% 0 0 50%; }
  100% {
    transform: rotate(180deg);
    margin: 50% 0 0 50%; } }
@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.arc-scale .loader {
  width: 100px;
  height: 100px; }
  .arc-scale .loader .arc {
    position: relative;
    margin: 25% 0 0 25%;
    width: 50%;
    height: 50%;
    border: 4px solid #FFF;
    border-radius: 50%;
    animation: rotate 2.9s infinite linear; }
  .arc-scale .loader .arc::before, .arc-scale .loader .arc::after {
    content: '';
    position: absolute;
    top: -5px;
    width: 33px;
    height: 62px;
    background-color: $color-black;
    animation: rotate 2s infinite ease; }
  .arc-scale .loader .arc::before {
    left: -6px;
    transform-origin: 29px 29px; }
  .arc-scale .loader .arc::after {
    left: 27px;
    transform-origin: 0 29px;
    animation-delay: 0.5s; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.clock .loader {
  width: 100px;
  height: 100px; }
  .clock .loader .arc {
    position: relative;
    margin: 23% 0 0 23%;
    border: 2px solid #FFF;
    width: 50px;
    height: 50px;
    border-radius: 50%; }
  .clock .loader .arc::after, .clock .loader .arc::before {
    content: '';
    position: absolute;
    top: 4%;
    left: 48%;
    width: 4%;
    height: 46%;
    background-color: #FFF;
    transform-origin: 50% 100%;
    border-radius: 5px;
    animation: rotate 2s infinite linear; }
  .clock .loader .arc::after {
    height: 36%;
    top: 14%;
    animation-duration: 12s; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.arc-rotate2 .loader {
  width: 100px;
  height: 100px; }
  .arc-rotate2 .loader .arc {
    position: absolute;
    width: 100%;
    height: 100%; }
  .arc-rotate2 .loader .arc::before, .arc-rotate2 .loader .arc::after {
    content: '';
    position: absolute;
    top: 32%;
    left: 32%;
    border: 2px solid;
    border-radius: 50%;
    width: 36%;
    height: 36%; }
  .arc-rotate2 .loader .arc::before {
    border-color: #FFF;
    opacity: .3; }
  .arc-rotate2 .loader .arc::after {
    border-color: transparent;
    border-bottom-color: #FFF;
    animation: rotate 1s infinite linear; }

@keyframes rotate {
  100% {
    transform: rotate(360deg); } }
.square-rotate-3d .loader {
  width: 100px;
  height: 100px; }
  .square-rotate-3d .loader .square {
    margin-top: 25%;
    margin-left: 25%;
    width: 50%;
    height: 50%;
    background-color: #FFF;
    animation: squareRotate3d 2s infinite ease-in-out; }

@keyframes squareRotate3d {
  25% {
    transform: perspective(100px) rotateX(180deg) rotateY(0); }
  50% {
    transform: perspective(100px) rotateX(180deg) rotateY(-180deg); }
  75% {
    transform: perspective(100px) rotateX(0) rotateY(-180deg); }
  100% {
    transform: perspective(100px) rotateX(0) rotateY(0); } }
#loaders::before, #loaders::after {
  content: "";
  display: table; }
#loaders::after {
  clear: both;
  overflow: hidden; }

.loader-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto; }

* {
  margin: 0;
  padding: 0; }

body {
  background-color: $color-black; }

#loaders {
  margin: 0 auto;
  width: 1000px; }

.loader-container {
  position: relative;
  float: left;
  width: 250px;
  height: 250px; }

/*# sourceMappingURL=output.css.map */
