.gallery {
  margin-top: 25px;

  .col {
    margin-bottom: 200px;

    @media(max-width:1024px) {
      margin-bottom: 132px;
    }

    @media(max-width:768px) {
      margin-bottom: 159px;
    }

    @media(max-width:540px) {
      margin-bottom: 92px;
    }
  }

  &__container {
    @media(max-width:540px) {
      padding-top: 60px;
    }

    .col {
      @media(max-width:540px) {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }

  &__item {
    cursor: pointer;

    &--2-column {
      width: 610px;
    }

    &--fill {
      width: 100%;
      height: 100%;
    }
    
    &-image {
      background-color: $color-picture-background;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      & img {
        filter: drop-shadow(4px 5px 5px rgba(0, 0, 0, 0.22));
        width: 85%;
      }
    }

    &-wrapper {
      @media(max-width: 540px) {
        margin-bottom: 10px;
      }
    }

    &-info {
      display: flex;
      justify-content: space-between ;

      @media(max-width: 540px) {
        display: block;
      }
    }

    &-title {
      font-size: 20px;
      max-width: 190px;

      @media(max-width:1200px) {
        font-size: 18px;
      }

      @media(max-width:540px) {
        font-size: 14px;
      }

      &--uppercase {
        font-weight: 500;
        text-transform: uppercase;
      }
    }

    &-size {
      font-size: 20px;

      @media(max-width:1200px) {
        font-size: 18px;
      }

      @media(max-width:540px) {
        font-size: 14px;
      }
    }

    &-year {
      font-size: 18px;

      @media(max-width:1200px) {
        font-size: 14px;
      }

      @media(max-width:540px) {
        font-size: 12px;
      }
    }
  }

  // &__item--first-column-2-column {
  //   grid-column: 1/span 2; 
  // }

  // &__item--second-column-2-column {
  //   grid-column: 2/span 2; 
  // }

  // &__item--third-column-2-column {
  //   grid-column: 3/span 2; 
  // }

  // &__item--fourth-column-2-column {
  //   grid-column: 4/ span 2; 
  // }

  // &__item--fifth-column-2-column {
  //   grid-column: 1/3; 
  // }
}

.vertical-big {
  min-height: 560px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;

  @media(max-width: 1024px) {
    min-height: 444px;
  }

  @media(max-width: 768px) {
    min-height: 651px;
  }

  @media(max-width: 540px) {
    min-height: 303px;
  }
}

.vertical-small {
  min-height: 435px;
  width: 100%;
  padding-bottom: 20px;
  padding-top: 20px;

  @media(max-width: 1024px) {
    min-height: 326px;
  }

  @media(max-width: 768px) {
    min-height: 502px;
  }

  @media(max-width: 540px) {
    min-height: 233px;
  }
}



.horizontal-small {
  min-height: 295px;
  padding-top: 20px;
  padding-bottom: 20px;

  @media(max-width: 1024px) {
    min-height: 232px;
  }

  @media(max-width: 768px) {
    min-height: 350px;
  }

  @media(max-width: 540px) {
    min-height: 162px;
  }
}

.empty {
  @media(max-width: 768px) {
    display: none;
  }
}