/* This stylesheet generated by Transfonter (https://transfonter.org) on February 25, 2018 4:00 PM */

@font-face {
  font-family: 'Grotesk';
  src: url('../assets/fonts/AltNeueGrotesk/Neue_Alte_Grotesk_Medium.otf');
  font-weight: 500;
}

@font-face {
  font-family: 'Grotesk';
  src: url('../assets/fonts/AltNeueGrotesk/Neue_Alte_Grotesk_Regular.otf');
  font-weight: 400;
}

@font-face {
  font-family: 'AcMultu';
  src: url('../assets/fonts/ACMutlu/AC-mutlu_unicode.otf');
  font-weight: 400;
}

